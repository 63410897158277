import * as React from "react";
import Layout from "../../components/Layout";

const PrivacyPage = () => {
  return (
    <Layout pageTitle="Privacy Policy">
      <h1 className="text-2xl font-bold">Privacy Policy</h1>
    </Layout>
  );
};

export default PrivacyPage;
